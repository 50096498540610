import { ResponseWithData } from './http';

export interface CacheConfig {
  key: string;
  refresh?: boolean;
  ttl?: number;
  usePrimerSessionCacheTtlHeader?: boolean;
}
// Storing the cache in the window to avoid bigger refactors, ideally,
// this would be provided by the loader.
// This is necessary because there are 2 cacheRequest instances,
// one for the loader and another for the core.
let storage = { cache: new Map() };
if (typeof window !== 'undefined') {
  window['__primerCache__'] ??= storage;
  storage = window['__primerCache__'];
}

export const memoryCache = (): Map<
  string,
  { promise: Promise<any>; exp: number }
> => storage.cache;

export const DEFAULT_TTL = 1000 * 60 * 60; // 1 hour
const CLEANUP_THRESHOLD = 30;
const MAX_CACHE_SIZE = 50;

function cleanupCache() {
  if (memoryCache().size <= CLEANUP_THRESHOLD) return;

  const now = Date.now();

  // Remove expired entries
  for (const [key, value] of memoryCache()) {
    if (value.exp < now) {
      memoryCache().delete(key);
    }
  }

  // Remove the oldest entries
  if (memoryCache().size > MAX_CACHE_SIZE) {
    for (const [key] of memoryCache()) {
      if (memoryCache().size <= MAX_CACHE_SIZE) break;
      memoryCache().delete(key);
    }
  }
}

export function setCache({ key, ttl = DEFAULT_TTL }: CacheConfig, result: any) {
  if (ttl) {
    memoryCache().set(key, {
      promise: Promise.resolve({ ...result, source: 'CACHE' }),
      exp: Date.now() + ttl,
    });
  } else {
    memoryCache().delete(key);
  }
}

export function cacheRequest<T>(config?: CacheConfig) {
  if (!config)
    return <F extends (...args: any[]) => Promise<ResponseWithData<T>>>(
      fn: F,
    ) => fn();

  const {
    key,
    refresh = false,
    usePrimerSessionCacheTtlHeader = false,
    ttl = DEFAULT_TTL,
  } = config;

  return async <F extends (...args: any[]) => Promise<ResponseWithData<T>>>(
    fn: F,
  ): Promise<ResponseWithData<T>> => {
    const requestTime = Date.now();

    const cached = memoryCache().get(key);

    if (!refresh && cached && cached.exp > requestTime) {
      return cached.promise;
    } else if (cached) {
      memoryCache().delete(key);
    }

    const promise = fn().then(
      (result) => {
        let finalTtl = ttl;
        if (usePrimerSessionCacheTtlHeader) {
          // Using || so if it's NaN it will set 0, converted from seconds to milliseconds
          finalTtl =
            (Number(result.headers.get('x-primer-session-cache-ttl')) || 0) *
            1000;
        }

        setCache({ key, ttl: finalTtl }, result);
        cleanupCache();
        return result;
      },
      (error) => {
        memoryCache().delete(key);
        throw error;
      },
    );

    memoryCache().set(key, {
      promise,
      exp: requestTime + ttl,
    });

    cleanupCache();

    return promise;
  };
}

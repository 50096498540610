import { Analytics, AnalyticsEvent, AnalyticsEventProperties } from '../types';

// Create or retrieve a symbol from the global symbol registry
const PRIMER_ANALYTICS_SYMBOL = Symbol.for('__primerAnalytics__');

interface StorageType {
  providers: Map<string, Analytics>;
  timers: Map<string, { start: number }>;
  eventsQueue: Map<string, AnalyticsEvent<AnalyticsEventProperties>[]>;
}

// Initial storage object
let storage: StorageType = {
  providers: new Map<string, Analytics>(),
  timers: new Map<string, { start: number }>(),
  eventsQueue: new Map<string, AnalyticsEvent<AnalyticsEventProperties>[]>(),
};

if (typeof window !== 'undefined') {
  const existingStorage = window[PRIMER_ANALYTICS_SYMBOL] as
    | StorageType
    | undefined;

  if (existingStorage) {
    storage = existingStorage;
  } else {
    window[PRIMER_ANALYTICS_SYMBOL] = storage;
  }
}

export const providers = (): Map<string, Analytics> => storage.providers;
export const timers = (): Map<string, { start: number }> => storage.timers;
export const eventsQueue = (): Map<
  string,
  AnalyticsEvent<AnalyticsEventProperties>[]
> => storage.eventsQueue;

const maxStringLength = 1024 * 5;

export function traverseObjectWithFunction(
  obj: any,
  fn: <T>(value: T) => T | string,
  visited = new WeakSet(),
  depth = 0,
  maxDepth = 10,
) {
  if (depth > maxDepth) return obj;
  if (visited.has(obj)) return obj;
  visited.add(obj);

  const processValue = (value: any) =>
    typeof value === 'object' && value !== null
      ? traverseObjectWithFunction(value, fn, visited, depth + 1, maxDepth)
      : fn(value);

  if (Array.isArray(obj)) return obj.map(processValue);

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, processValue(value)]),
  );
}

// Trim string properties to a threshold to prevent slowdowns due to sending big
// analytics messages. The threshold is arbitrary; tune as needed.
export function trimIfString<T>(value: T) {
  if (typeof value === 'string' && value.length > maxStringLength)
    return `${value.slice(0, maxStringLength)}...`;

  return value;
}

// Replace functions with their function names if possible
export function stringifyFunction<T>(value: T) {
  if (typeof value === 'function') return value.name || 'function';

  return value;
}

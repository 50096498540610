import {
  Analytics,
  AnalyticsEventProperties,
  AnalyticsEvents,
  createAnalyticsEvent,
} from '../../analytics/types';
import { defaultUrl, isPlaywrightTests } from '../../analytics/config/urls';

interface MakeCrashEventCallParams {
  checkoutSessionId?: string;
  analytics: Analytics | null | undefined;
  error: unknown;
  reporter?: string;
}

function processError(error: unknown): {
  errorBody: string;
  stacktrace?: string;
} {
  if (error instanceof Error) {
    return {
      errorBody: error.message,
      stacktrace: error.stack,
    };
  }
  if (typeof error === 'object' && error !== null && 'message' in error) {
    return {
      errorBody: String(error.message),
      stacktrace: 'stack' in error ? String(error.stack) : undefined,
    };
  }
  return {
    errorBody: String(error || 'Unknown error'),
  };
}

export function makeCrashEventCall({
  checkoutSessionId,
  analytics,
  error,
  reporter,
}: MakeCrashEventCallParams): void {
  if (analytics?.crashEvent) {
    analytics.crashEvent({
      ...processError(error),
      reporter,
    });
  } else {
    checkoutSessionId &&
      postCrashEventBackup(checkoutSessionId, error, reporter);
  }
}

export async function postCrashEventBackup(
  checkoutSessionId: string,
  error: unknown,
  reporter?: string,
): Promise<void> {
  // Post the error to the server
  const url = defaultUrl;

  if (isPlaywrightTests) {
    return;
  }

  const event = await createAnalyticsEvent<AnalyticsEventProperties>(
    AnalyticsEvents.CRASH_EVENT,
    {
      ...processError(error),
      reporter,
    },
    {
      checkoutSessionId,
    },
  );

  try {
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(event),
    });
  } catch (e) {
    console.error('Failed to post crash event', e);
  }
}

import { Analytics } from '../analytics/types';
import { SDKError } from './SDKError';
import { makeCrashEventCall } from './utils/makeCrashEventCall';

// Create or retrieve a symbol from the global symbol registry
const LOADED_SYMBOL = Symbol.for('initGlobalErrorMonitoringLoaded');

export const initGlobalErrorMonitoring = (
  checkoutSessionId: string,
  analytics: Analytics,
) => {
  if (typeof window !== 'undefined' && window[LOADED_SYMBOL]) return;
  window[LOADED_SYMBOL] = true;

  // Handle general JS errors
  window.addEventListener('error', (event) => {
    if (shouldIgnoreError(event)) return;

    if (shouldReportError(event)) {
      makeCrashEventCall({
        checkoutSessionId,
        analytics,
        error: event.error,
        reporter: 'window.onerror',
      });
    }
  });

  // Handle unhandled promise rejections
  window.addEventListener('unhandledrejection', (event) => {
    const error = event.reason || new Error('Unhandled promise rejection');

    if (shouldReportUnhandledRejection(error)) {
      makeCrashEventCall({
        checkoutSessionId,
        analytics,
        error,
        reporter: 'unhandledrejection',
      });
    }
  });
};

const shouldIgnoreError = (event) => {
  // Ignore cross-origin script errors and errors with no useful data
  return (
    event.message === 'Script error.' || !event.filename || event.lineno === 0
  );
};

// Helper function to determine if the error is from the SDK (originates from *.primer.io)
const shouldReportError = (event) => {
  const filename = event.filename;

  if (filename && isPrimerIoDomain(filename)) {
    const sdkError = new SDKError(event.error);
    if (sdkError.isReported) {
      return true;
    }
  }
  return false;
};

// Helper function to determine if the unhandled rejection is from the SDK
const shouldReportUnhandledRejection = (error: unknown): boolean => {
  if (
    error &&
    typeof error === 'object' &&
    'stack' in error &&
    typeof (error as Error).stack === 'string'
  ) {
    const sdkError = SDKError.from(error);

    if (sdkError.isReported) {
      return false;
    }

    // Try to use error.fileName if available
    if ('fileName' in error && typeof (error as any).fileName === 'string') {
      const fileName = (error as any).fileName;
      if (isPrimerIoDomain(fileName)) {
        return true;
      }
    }

    // Fallback to parsing the stack trace
    const fileUrls = getFileUrlsFromStack(sdkError.stack);
    return fileUrls.some(isPrimerIoDomain);
  }
  return false;
};

// Helper function to check if a URL belongs to *.primer.io
const isPrimerIoDomain = (url: string): boolean => {
  try {
    const parsedUrl = new URL(url, window.location.origin);
    return parsedUrl.hostname.endsWith('.primer.io');
  } catch (e) {
    // If URL parsing fails, ignore this line
    return false;
  }
};

// Helper function to extract file URLs from the stack trace
const getFileUrlsFromStack = (stack?: string): string[] => {
  if (!stack) {
    return [];
  }
  const lines = stack.split('\n');
  const urls: string[] = [];

  // Regular expression to match stack trace lines and extract URLs
  const stackLineRegex = /^\s*at\s+(?:.*?\s+)?\(?(.+?):\d+:\d+\)?$/;

  for (const line of lines) {
    const match = line.match(stackLineRegex);
    if (match && match[1]) {
      urls.push(match[1]);
    } else {
      // Handle Firefox and Safari stack trace formats
      const altStackLineRegex = /@(.*?):\d+:\d+$/;
      const altMatch = line.match(altStackLineRegex);
      if (altMatch && altMatch[1]) {
        urls.push(altMatch[1]);
      }
    }
  }

  return urls;
};

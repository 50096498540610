/* eslint-disable no-bitwise */

// https://gist.github.com/jed/982883

// https://caniuse.com/mdn-api_crypto_randomuuid
export const uuid = (): string => $crypto.randomUUID?.() || randomUUID();

// TODO: remove when IE and Safari < 15.4 support is dropped
const randomUUID = () =>
  // prettier-ignore
  // @ts-expect-error adding number and array
  (([1e7]+-1e3+-4e3+-8e3+-1e11) as string).replace(/[018]/g, (c: number) =>
    (c ^ random() >> c / 4).toString(16)
  );

// TODO: remove Math.random when Opera Mini support is dropped - https://caniuse.com/getrandomvalues
const random = () =>
  ($crypto.getRandomValues?.(new Uint8Array(1))[0] ?? Math.random() * 16) & 15;

// Server Side Rendering check
const $crypto = typeof crypto !== 'undefined' ? crypto : ({} as Crypto);

// TODO: remove when other packages' TypeScript is updated, most are still on 4.1.3
declare global {
  interface Crypto {
    randomUUID(): string;
  }
}

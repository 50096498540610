declare const process: {
  env: Record<string, string>;
};

export const Environment = {
  get<T = unknown>(key: string, defaultValue?: T): string | T {
    let value: string | undefined;

    try {
      switch (key) {
        case 'PRIMER_SDK_VERSION':
          value = process.env.PRIMER_SDK_VERSION;
          break;
        case 'PRIMER_ASSETS_URL':
          value = process.env.PRIMER_ASSETS_URL;
          break;
        case 'PRIMER_CORE_API_URL':
          value = process.env.PRIMER_CORE_API_URL;
          break;
        case 'PRIMER_BUILD_INTEGRATION_BUILDER':
          value = process.env.PRIMER_BUILD_INTEGRATION_BUILDER;
          break;

        default:
          break;
      }
    } catch (e) {}

    return value || <T>defaultValue;
  },
};

enum BatteryStatus {
  CHARGING = 'CHARGING',
  NOT_CHARGING = 'NOT_CHARGING',
}

export interface DeviceInfo {
  batteryLevel?: number;
  batteryStatus?: BatteryStatus;
  memoryFootprint?: number;
  modelIdentifier?: string;
  modelName?: string;
  screen: {
    height: number;
    width: number;
  };
  locale: string;
  userAgent: string;
}

export async function getDeviceInfo(): Promise<DeviceInfo | undefined> {
  // Server Side Rendering check
  if (typeof navigator === 'undefined' || typeof performance === 'undefined') {
    return undefined;
  }

  // eslint-disable-next-line compat/compat
  const battery = await navigator.getBattery?.();

  return {
    // eslint-disable-next-line compat/compat
    memoryFootprint: performance.memory?.usedJSHeapSize,
    screen: {
      height: screen?.height,
      width: screen?.width,
    },
    userAgent: navigator.userAgent,
    locale: navigator.language,
    batteryLevel: battery?.level,
    batteryStatus: battery?.charging
      ? BatteryStatus.CHARGING
      : BatteryStatus.NOT_CHARGING,
  };
}

declare global {
  interface Navigator {
    getBattery?(): Promise<{
      charging: boolean;
      level: number;
    }>;
  }

  interface Performance {
    memory?: {
      usedJSHeapSize?: number;
    };
  }
}

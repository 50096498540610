import { AnalyticsData } from './AnalyticsAction';

export interface CrashEventProperties {
  errorBody: string;
  stacktrace?: string;
  reporter?: string;
}

export type MessageSeverity = 'ERROR' | 'DEBUG' | 'WARN' | 'INFO';

export interface MessageEventProperties {
  messageType: string;
  message: string;
  severity?: MessageSeverity;
  diagnosticId?: string;
  [k: string]: string | number | undefined;
}

export enum NetworkCallType {
  REQUEST_START = 'REQUEST_START',
  REQUEST_END = 'REQUEST_END',
}

export type NetworkEventProperties = {
  id: string;
  url: string;
  method: string;
  errorBody?: string;
  responseCode?: number;
  duration?: number;
  callType: NetworkCallType;
};

export enum TimerType {
  START = 'START',
  END = 'END',
}

export type TimerId =
  | 'CONFIGURATION_LOADING'
  | 'HEADLESS_LOADING'
  | 'EXPRESS_LOADING'
  | 'DROP_IN_LOADING'
  | 'VAULT_MANAGER_LOADING';

export const timerIdCheckoutFlowMap: Record<string, TimerId> = {
  createHeadless: 'HEADLESS_LOADING',
  showExpressCheckout: 'EXPRESS_LOADING',
  showUniversalCheckout: 'DROP_IN_LOADING',
  showVaultManager: 'VAULT_MANAGER_LOADING',
} as const;

export interface TimerEventProperties {
  id: TimerId;
  context?: {
    [k: string]: string | number | undefined;
  };
}

export interface SdkFunctionEventProperties {
  name: string;
  params?: object;
  module?: string;
}

export type V1EventProperties = {
  data?: AnalyticsData;
  event: string;
};

export type AnalyticsEventProperties =
  | CrashEventProperties
  | MessageEventProperties
  | NetworkEventProperties
  | SdkFunctionEventProperties
  | TimerEventProperties
  | V1EventProperties;

import { AnalyticsEvent, AnalyticsEventProperties } from '../types';
import { eventsQueue } from './storage';

const eventsBeaconTimeout = 500;
const eventsBeaconTimeoutIds = new Map<string, number>();
const MAX_PAYLOAD_SIZE = 64000; // 64KB
const MAX_RETRY_ATTEMPTS = 5; // Maximum number of retry attempts

export function sendEvents(url: string, immediate = false, attempt = 1) {
  if (eventsBeaconTimeoutIds.has(url)) {
    const id = eventsBeaconTimeoutIds.get(url);
    clearTimeout(id);
    eventsBeaconTimeoutIds.delete(url);
  }

  const send = () => {
    const events = eventsQueue().get(url);
    if (events?.length) {
      let sent = true;
      let batch: AnalyticsEvent<AnalyticsEventProperties>[] = [];
      let batchSize = 0;
      let eventIndex = 0;

      while (eventIndex < events.length) {
        const event = events[eventIndex];
        const eventString = JSON.stringify(event);
        const eventSize = new Blob([eventString]).size;

        if (eventSize > MAX_PAYLOAD_SIZE) {
          // Skip this event as it's too large to send
          console.error(
            'Event size exceeds maximum payload size and will be skipped:',
            event,
          );
          eventIndex++;
          continue;
        }

        if (batchSize + eventSize > MAX_PAYLOAD_SIZE && batch.length > 0) {
          // Send current batch
          try {
            sent = navigator.sendBeacon(url, JSON.stringify(batch));
          } catch (error) {
            sent = false;
            console.error('Unable to send events', error);
          }

          if (!sent) break;

          // Reset batch
          batch = [];
          batchSize = 0;
        }

        batch.push(event);
        batchSize += eventSize;
        eventIndex++;
      }

      // Send any remaining events in the batch
      if (batch.length > 0 && sent) {
        try {
          sent = navigator.sendBeacon(url, JSON.stringify(batch));
        } catch (error) {
          sent = false;
          console.error('Unable to send events', error);
        }
      }

      if (sent) {
        // All batches sent successfully, clear the events queue for this URL
        eventsQueue().delete(url);
      } else if (attempt < MAX_RETRY_ATTEMPTS) {
        // Retain the events in the queue and retry with exponential backoff
        const retryTimeout = eventsBeaconTimeout * Math.pow(2, attempt);
        setTimeout(() => sendEvents(url, immediate, attempt + 1), retryTimeout);
      } else {
        console.error('Max retry attempts reached. Events will not be sent.');
      }
    }
  };

  if (immediate) {
    send();
    return;
  }

  const id = setTimeout(send, eventsBeaconTimeout) as unknown as number;
  eventsBeaconTimeoutIds.set(url, id);
}

export function queueEvent(
  url: string,
  event: AnalyticsEvent<AnalyticsEventProperties>,
) {
  if (!eventsQueue().has(url)) eventsQueue().set(url, []);
  const queue = eventsQueue().get(url);
  queue?.push(event);

  sendEvents(url);
}

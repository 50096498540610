import { SDKError } from '@primer-sdk-web/errors/SDKError';
import { getAnalytics } from '../analytics/analytics';
import { timerIdCheckoutFlowMap } from '../analytics/types';
import { fetchClientConfiguration } from '../core/fetchClientConfiguration';
import { initGlobalErrorMonitoring } from '../errors/initGlobalErrorMonitoring';
import {
  makeCrashEventCall,
  postCrashEventBackup,
} from '../errors/utils/makeCrashEventCall';
import { uuid } from '../utils/uuid';
import { loadPrimer } from './load';

type PrimerClient = typeof import('../Primer');
type Tail<T extends unknown[]> = T extends [any, any, ...infer P] ? P : never;
type PrimerFunctions = Exclude<keyof PrimerClient, 'SDK_VERSION'>;
type ArgsOf<T extends PrimerFunctions> = Tail<Parameters<PrimerClient[T]>>;
type ReturnTypeOf<T extends PrimerFunctions> = Awaited<
  ReturnType<PrimerClient[T]>
>;

if (!process.env.PRIMER_SDK_VERSION) {
  throw new Error('Environment variable PRIMER_SDK_VERSION is required.');
}
export const SDK_VERSION = process.env.PRIMER_SDK_VERSION;

export const createHeadless = lazyLoad('createHeadless');
export const showExpressCheckout = lazyLoad('showExpressCheckout');
export const showUniversalCheckout = lazyLoad('showUniversalCheckout');
export const showVaultManager = lazyLoad('showVaultManager');

function lazyLoad<FnName extends PrimerFunctions>(name: FnName) {
  return async (...[clientToken, options, ...rest]: ArgsOf<FnName>) => {
    const checkoutSessionId = uuid();
    try {
      const analytics = getAnalytics(checkoutSessionId);
      try {
        initGlobalErrorMonitoring(checkoutSessionId, analytics);

        analytics.sdkFunctionEvent({
          name,
          params: [clientToken, options, ...rest],
        });

        analytics.timerStart({ id: timerIdCheckoutFlowMap[name] });

        const [config, client] = await Promise.all([
          fetchClientConfiguration(
            clientToken,
            checkoutSessionId,
            options?.clientSessionCachingEnabled
              ? {
                  key: clientToken,
                  usePrimerSessionCacheTtlHeader: true,
                }
              : undefined,
          ),
          loadPrimer(analytics),
          // startMockServiceWorker(),
        ]);

        return client[name](
          config,
          checkoutSessionId,
          clientToken,
          options,
          ...(rest as []),
        ) as ReturnTypeOf<FnName>;
      } catch (error: unknown) {
        const sdkError = SDKError.from(error);
        if (!sdkError.isReported) {
          makeCrashEventCall({
            checkoutSessionId: checkoutSessionId,
            analytics: analytics,
            error: sdkError,
            reporter: 'lazyLoad level 1',
          });
          sdkError.markAsReported();
        }
        throw sdkError;
      }
    } catch (error: unknown) {
      const sdkError = SDKError.from(error);
      if (!sdkError.isReported) {
        postCrashEventBackup(checkoutSessionId, sdkError, 'lazyLoad level 0');
        sdkError.markAsReported();
      }
      throw sdkError;
    }
  };
}

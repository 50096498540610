import { defaultUrl, isPlaywrightTests } from './config/urls';
import {
  Analytics,
  AnalyticsEventProperties,
  AnalyticsEvents,
  AnalyticsOptions,
  createAnalyticsEvent,
  TimerEventProperties,
  TimerType,
} from './types';
import { queueEvent, sendEvents } from './utils/eventSender';
import { providers, timers } from './utils/storage';
import {
  stringifyFunction,
  traverseObjectWithFunction,
  trimIfString,
} from './utils/utils';

let visibilityChangeListenerAdded = false;

export function createAnalytics({
  url = defaultUrl,
  ...options
}: AnalyticsOptions) {
  // Server-Side Rendering check
  if (typeof document !== 'undefined' && !visibilityChangeListenerAdded) {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') sendEvents(url, true);
    });
    visibilityChangeListenerAdded = true;
  }

  const trackEvent =
    (eventType: AnalyticsEvents) =>
    async (properties: AnalyticsEventProperties) => {
      // Server-Side Rendering check
      if (typeof navigator === 'undefined') return;

      const event = await createAnalyticsEvent<AnalyticsEventProperties>(
        eventType,
        properties,
        options,
      );

      const mapValue = <T>(value: T) => trimIfString(stringifyFunction(value));

      if (isPlaywrightTests) return;

      const trimmedEvent = traverseObjectWithFunction(event, mapValue);

      queueEvent(url, trimmedEvent);
    };

  const provider: Analytics = {
    crashEvent: trackEvent(AnalyticsEvents.CRASH_EVENT),
    messageEvent: trackEvent(AnalyticsEvents.MESSAGE_EVENT),
    networkCallEvent: trackEvent(AnalyticsEvents.NETWORK_CALL_EVENT),
    sdkFunctionEvent: trackEvent(AnalyticsEvents.SDK_FUNCTION_EVENT),
    timerStart: ({ id, ...rest }: TimerEventProperties) => {
      const timerKey = `${options.checkoutSessionId}|${id}`;
      const now = performance?.now ? performance.now() : Date.now();
      timers().set(timerKey, {
        start: now,
      });

      return trackEvent(AnalyticsEvents.TIMER_EVENT)({
        ...rest,
        id,
        timerType: TimerType.START,
      });
    },
    timerEnd: ({ id, ...rest }: TimerEventProperties) => {
      const timerKey = `${options.checkoutSessionId}|${id}`;
      const { start = undefined } = timers().get(timerKey) ?? {};
      timers().delete(timerKey);
      const now = performance?.now ? performance.now() : Date.now();

      const duration = start ? now - start : undefined;

      return trackEvent(AnalyticsEvents.TIMER_EVENT)({
        ...rest,
        id,
        timerType: TimerType.END,
        duration,
      });
    },
    v1Event: trackEvent(AnalyticsEvents.V1_EVENT),
    url,
  };

  providers().set(options.checkoutSessionId, provider);

  return getAnalytics(options.checkoutSessionId);
}

export function getAnalytics(checkoutSessionId: string) {
  // Returning a proxy to avoid refactoring the entire SDK
  // Will return the methods from the most up-to-date analytics provider
  return new Proxy(
    {},
    {
      get(_, prop) {
        const provider =
          providers().get(checkoutSessionId) ??
          createAnalytics({ checkoutSessionId });
        return provider[prop as keyof Analytics];
      },
    },
  ) as Analytics;
}

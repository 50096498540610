export class SDKError extends Error {
  public isReported: boolean;

  constructor(message: string, isReported: boolean = false) {
    super(message);
    this.isReported = isReported;
    this.name = this.constructor.name;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }

  public static from(error: unknown, isReported: boolean = false): SDKError {
    if (error instanceof SDKError) {
      return error;
    }

    const message = error instanceof Error ? error.message : String(error);
    const sdkError = new SDKError(message, isReported);

    if (error instanceof Error && error.stack) {
      sdkError.stack = error.stack;
      Object.assign(sdkError, error);
    }

    return sdkError;
  }

  public markAsReported(): void {
    this.isReported = true;
  }
}

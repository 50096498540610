import {
  createAnalytics,
  getAnalytics,
} from '@primer-sdk-web/analytics/analytics';
import { prepareNetworkEventProps } from '@primer-sdk-web/analytics/utils/modules';
import {
  RetryableError,
  RetryConfig,
  retryWithExponentialBackoff,
} from './retryWithExponentialBackoff';
import { CacheConfig, cacheRequest } from './cacheRequest';
import { uuid } from './uuid';
import { ClientConfiguration } from '@primer-sdk-web/models/ClientConfiguration';

export type LogFunction = (error: any) => void;
export type ResponseWithData<T> = {
  data: T;
  source: 'CACHE' | 'NETWORK';
} & Pick<Response, 'headers' | 'status'>;

const DEFAULT_TIMEOUT = 15000; // 15 seconds

interface RequestConfig {
  checkoutSessionId: string;
  headers?: Record<string, string>;
  cacheConfig?: CacheConfig;
  retryConfig?: RetryConfig;
  timeout?: number;
}

export const get = <T>(
  checkoutSessionId: string,
  url: string,
  headers?: Record<string, string>,
) => request<T>(checkoutSessionId, 'get', url, headers);

export const post = <T>(
  checkoutSessionId: string,
  url: string,
  payload: Record<string, any>,
  headers?: Record<string, string>,
) =>
  request<T>(checkoutSessionId, 'post', url, headers, JSON.stringify(payload));

const request = async <T>(
  checkoutSessionId: string,
  method: 'get' | 'post',
  url: string,
  headers?: Record<string, string>,
  body?: string,
): Promise<ResponseWithData<T>> => {
  const id = uuid();
  const start = performance?.now();
  let analytics = getAnalytics(checkoutSessionId);
  const analyticsPayload = { method, url, body };
  analytics.networkCallEvent(prepareNetworkEventProps(id, analyticsPayload));

  try {
    const response = await fetch(url, {
      body,
      method,
      headers: {
        'content-type': 'application/json',
        'x-request-id': id,
        ...headers,
      },
    });

    if (!response.ok) {
      const error: RetryableError = new Error(
        `HTTP error! status: ${response.status}`,
      );
      error.status = response.status;
      throw error;
    }

    const data = await response.json();

    if (url.includes('client-sdk/configuration')) {
      const {
        primerAccountId,
        clientSession: { clientSessionId, customer },
      } = data as ClientConfiguration;
      analytics = createAnalytics({
        checkoutSessionId,
        clientSessionId,
        customerId: customer?.customerId,
        primerAccountId,
        url: analytics.url,
      });
    }

    const end = performance?.now();
    const duration = end - start;

    analytics.networkCallEvent(
      prepareNetworkEventProps(id, analyticsPayload, { data }, duration),
    );

    return {
      data,
      headers: response.headers,
      status: response.status,
      source: 'NETWORK',
    };
  } catch (error) {
    const end = performance?.now();
    const duration = end - start;
    Object.assign(error, { isNetworkError: error instanceof TypeError });

    analytics.networkCallEvent(
      prepareNetworkEventProps(id, analyticsPayload, { error }, duration),
    );

    throw error;
  }
};

const fetchWithTimeout = <T>(
  fetchPromise: Promise<T>,
  timeout: number,
): Promise<T> => {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      const timeoutError: RetryableError = new Error('Request timed out');
      timeoutError.isNetworkError = true;
      reject(timeoutError);
    }, timeout);

    fetchPromise
      .then(resolve)
      .catch(reject)
      .finally(() => clearTimeout(timeoutId));
  });
};

export const retryableGet = <T>(url: string, config: RequestConfig) => {
  const {
    headers,
    retryConfig,
    cacheConfig,
    timeout = DEFAULT_TIMEOUT,
    checkoutSessionId,
  } = config;
  const analytics = getAnalytics(checkoutSessionId);
  return cacheRequest<T>(cacheConfig)(() =>
    retryWithExponentialBackoff(
      () => fetchWithTimeout(get<T>(checkoutSessionId, url, headers), timeout),
      retryConfig,
      (properties) => analytics.messageEvent({ ...properties, url }),
    ),
  );
};

export const retryablePost = <T>(
  url: string,
  payload: Record<string, any>,
  config: RequestConfig,
) => {
  const {
    headers,
    retryConfig,
    cacheConfig,
    timeout = DEFAULT_TIMEOUT,
    checkoutSessionId,
  } = config;
  const analytics = getAnalytics(checkoutSessionId);
  return cacheRequest<T>(cacheConfig)(() =>
    retryWithExponentialBackoff(
      () =>
        fetchWithTimeout(
          post<T>(checkoutSessionId, url, payload, headers),
          timeout,
        ),
      retryConfig,
      (properties) => analytics.messageEvent({ ...properties, url }),
    ),
  );
};
